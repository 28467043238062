import React from "react";
import PropTypes from "prop-types";

import Image from "components/Image";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.scss";

const Next = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      role="button"
      tabIndex="0"
      aria-label="Next image"
      className={className}
      onClick={onClick}
    />
  );
}

const Prev = (props) => {
  const { className, style, onClick } = props;
  return (
    <div 
      role="button"
      tabIndex="0"
      aria-label="Previous image"
      className={className}      
      onClick={onClick}
    />
  );
}

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  adaptiveHeight: false, 
  autoplaySpeed: 3000,
  pauseOnDotsHover: true,
  nextArrow: <Next />,
  prevArrow: <Prev />
}

const Carousel = ({gallery}) => {

  return (
    <Slider {...settings} className="carousel">
      {gallery.map(({fileName, alt}) => (
          <Image className="img-fluid d-block mx-auto carousel-image" key={fileName} fileName={fileName} alt={alt}  imgStyle={{objectFit: "contain"}}/>
      ))}      
    </Slider>
  );
}

Carousel.propTypes = {
  gallery: PropTypes.array.isRequired,
};

Next.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

Prev.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default Carousel;
