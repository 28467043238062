import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row } from "react-bootstrap";
import PageSection from "components/PageSection";
import SectionHeader from "components/SectionHeader";
import Carousel from "components/Carousel";

import "./Gallery.scss";

const Gallery = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, gallery } = frontmatter;

  return (
    <PageSection className={clsx("gallery-section", className)} id={anchor}>
       <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
        <Carousel gallery={gallery}/>
      </Row>
    </PageSection>
  );
};

Gallery.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Gallery.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Gallery;
